define('common/components/inactivity-modal/behaviors/inactivity',[
        'app',
        'common/components/inactivity-modal/views/inactivity-modal'
    ], function (app, InactivityModal) {
    "use strict";

    return Mn.Behavior.extend({
        "apiURLs": {
            "inactivityTimeout": '/apiproxy/v1/users/inactivity_timeout',
            "sessionState": '/apiproxy/v1/users/session_state',
            "suggestSignIn": '/apiproxy/v1/users/suggest_signin'
        },

        "isInactive": false,

        "inactivityModal": null,
        "inactivityTimeout": null,
        "inactivityTimer": null,
        "session_ttl": null,

        "loginScreen": null,

        // Uses '' as default for system to redirect to default
        "signInPath": '',

        "initialize": function () {
            // Create the user object
            this.user = new Backbone.Model(app.initData.get("user"));

            _.bindAll(
                this,
                'onActivity',
                'onInactivity',
                'onMessageWindow',
                'onError'
            );

            this.setupInitialTimeouts();
        },

        "setupInitialTimeouts": function () {
            // Grab the session_ttl from the init data if available
            this.session_ttl = app.initData.get('session_ttl');
            if (this.session_ttl) {
                this.session_ttl = window.parseInt(this.session_ttl, 10);
            }

            // Set a time to trigger at the point of session expiration (service returns seconds until midnight)
            if (!this.session_ttl || _.isNaN(this.session_ttl)) {
                $.get(this.apiURLs.sessionState)
                    .done(function (sessionData) {
                        if (sessionData.session_ttl && _.isNumber(sessionData.session_ttl)) {
                            window.setTimeout(this.onInactivity, sessionData.session_ttl);
                            this.setupInactivityTimeout();
                        }
                    }.bind(this))
                    .fail(this.onError);
            } else {
                window.setTimeout(this.onInactivity, this.session_ttl);
                this.setupInactivityTimeout();
            }
        },

        "setupInactivityTimeout": function () {
            // Grab the inactivity timeout from the init data if available
            var product_settings = app.initData.get('product_settings');
            if (product_settings && product_settings.INACTIVITYTIMEOUT) {
                this.inactivityTimeout = window.parseInt(product_settings.INACTIVITYTIMEOUT, 10);
            }

            if (!this.inactivityTimeout || _.isNaN(this.inactivityTimeout)) {
                $.get(this.apiURLs.inactivityTimeout)
                    .done(function (data) {
                        if (!data.inactivity_timeout) {
                            return;
                        }

                        this.inactivityTimeout = window.parseInt(data.inactivity_timeout, 10);

                        // Set inactivity timer for use to reactive timer when necessary
                        if (!_.isNaN(this.inactivityTimeout)) {
                            // Attach the activity listeners AFTER retrieving the inactivity timeout.
                            this.attachActivityListeners();
                            this.setInactivityTimer();
                        }
                    }.bind(this))
                    .fail(this.onError);
            } else {
                this.attachActivityListeners();
                this.setInactivityTimer();
            }
        },

        "setupSignin": function() {
            // Only perform this once per behavior's existence
            var suggestSignEP,
                nextURL;

            nextURL = window.origin.replace(/(.*:\/\/\w+\.)/, "https://app.") + '/learn/login_signal?pmo=' + window.encodeURIComponent(window.origin);

            suggestSignEP = this.apiURLs.suggestSignIn +
                            '?next_url=' +
                            window.encodeURIComponent(nextURL);

            $.get(suggestSignEP)
                .done(
                    _.bind(function(data) {
                        var recommendedSignin = '/learn/signin';
                        if (data.suggest && data.suggest.recommended) {
                            recommendedSignin = data.suggest.recommended;
                        }
                        this.user.set("recommendedSignin", recommendedSignin);
                        this.inactivityModal.showFooter();
                    }, this))
                .fail(this.onError);
        },

        "attachActivityListeners": function () {
            $(document).on(
                'click.inactivity mousemove.inactivity keypress.inactivity',
                this.onActivity
            );
        },

        "detachActivityListeners": function () {
            $(document).off('click.inactivity mousemove.inactivity keypress.inactivity');
            $(window).off('message.inactivity');
        },

        "resetInactivityTimer": function () {
            this.clearInactivityTimer();
            this.setInactivityTimer();
        },

        "setInactivityTimer": function () {
            this.inactivityTimer = window.setTimeout(this.onInactivity, this.inactivityTimeout);
        },

        "clearInactivityTimer": function () {
            if (this.inactivityTimer) {
                window.clearTimeout(this.inactivityTimer);
            }
        },

        "checkLoginAndTriggerModal": function () {
            this.detachActivityListeners();

            $.get(this.apiURLs.sessionState)
                .done(
                    function (data) {
                        // Check to see if the user is authenticated via lti to update how the modal interacts
                        var isLTIUser = false;
                        if (window.frameElement && data.authentication_source === 'lti') {
                            isLTIUser = true;
                        }

                        // Either we are no longer logged in or we no longer own the session
                        if (data.session_owner && this.user.id &&
                            data.session_owner.toLowerCase() !== this.user.id.toLowerCase() ||
                            data.session_status !== 'logged_in') {
                            this.showInactivityModal(isLTIUser);
                        } else {
                            this.isInactive = false;
                            this.attachActivityListeners();
                        }
                    }.bind(this))
                .fail(this.onError);
        },

        "onActivity": _.throttle(function () {
            if (this.isInactive) {
                // The page has been marked inactive.
                // Trigger the inactivity workflow.
                this.checkLoginAndTriggerModal();
                return;
            }

            this.resetInactivityTimer();
        }, 500),

        "onDestroy": function () {
            this.detachActivityListeners();
            this.clearInactivityTimer();

            if (this.inactivityModal) {
                // dismiss, implicitly destroys the modal
                this.inactivityModal.dismiss();

                this.inactivityModal = null;
            }

            if (this.loginScreen) {
                // close the popup window
                this.loginScreen.close();
            }
        },

        "onInactivity": function () {
            this.isInactive = true;
        },

        "onMessageWindow": function (event) {
            $.get(this.apiURLs.sessionState)
                .done(function (data) {
                    var session_status = data.session_status;

                    // If a different user logged in, redirect the user
                    if (data.session_owner && this.user.id &&
                        data.session_owner.toLowerCase() !== this.user.id.toLowerCase()) {
                        window.location.replace('');
                    // If the user logged in with the same user, reset the modal
                    } else if (session_status === 'logged_in') {
                        this.inactivityModal.dismiss();
                        this.isInactive = false;
                    }
                }.bind(this))
                .fail(this.onError);
        },

        "onModalClickFocusWindow": function () {
            if (this.loginScreen && !this.loginScreen.closed) {
                this.loginScreen.focus();
            } else {
                this.showLoginWindow();
            }
        },

        "onModalDismiss": function () {
            // Re-enable the modal after it's been dismissed
            this.attachActivityListeners();

            this.stopListening(this.inactivityModal);
            this.inactivityModal = null;
            this.isInactive = false;
        },

        "onModalSave": function () {
            // Bind signal return to verify session was restored after hitting login
            $(window).one(
                'message.inactivity',
                this.onMessageWindow
            );

            // Display login prompt and inform the user that they have to complete the login workflow
            this.showLoginWindow();

            if (!this.loginScreen) {
                // If pop ups are blocked, report this to the user
                this.inactivityModal.model.set('popupBlocked', true);
                this.inactivityModal.hideLogin();
                return;
            }

            this.inactivityModal.model.set('isLoginPrompted', true);
            this.inactivityModal.hideFooter();
        },

        "showInactivityModal": function (isLTIUser) {
            // Create the inactivity modal and display to the user
            this.user.set({
                "isLoginPrompted": false,
                "isLTIUser": isLTIUser,
                "isSystemDown": false
            });

            this.inactivityModal = new InactivityModal({
                "model": this.user
            });

            this.listenTo(this.inactivityModal, 'click:focus-window', this.onModalClickFocusWindow);
            this.listenToOnce(this.inactivityModal, 'dismiss', this.onModalDismiss);
            this.listenToOnce(this.inactivityModal, 'save', this.onModalSave);

            this.inactivityModal.render();
            if (!this.user.get("recommendedSignin")) {
                this.setupSignin();
                this.inactivityModal.hideFooter();
            }
        },

        "showLoginWindow": function () {
            var suggestedSignin = this.user.get("recommendedSignin");

            this.loginScreen = window.open(
               suggestedSignin,
               'sign-in-' + this.userID
            );
        },

        "onError": function(data) {
            this.resetInactivityTimer();
            if (!_.contains([0, 429, 500], data.status)) {
                return;
            }

            this.user.set({
                'isSystemDown': true
            });

            this.inactivityModal = new InactivityModal({
                "model": this.user
            });
            this.listenToOnce(this.inactivityModal, 'dismiss', this.onModalDismiss);
            this.inactivityModal.render();
            this.inactivityModal.hideLogin();
        }
    });
});

